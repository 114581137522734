
import { Component, Vue } from "vue-property-decorator";
import { store } from "@/store";
import { dispatchCheckLoggedIn } from "@/store/main/actions";
import { readIsLoggedIn, readActiveWorkspace } from "@/store/main/getters";

const startRouteGuard = async (to, from, next) => {
  await dispatchCheckLoggedIn(store);
  if (readIsLoggedIn(store)) {
    if (to.path === "/login" || to.path === "/") {
      next("/main/" + readActiveWorkspace(store) + "/classification/view");
    } else {
      next();
    }
  } else if (readIsLoggedIn(store) === false) {
    if (
      to.path === "/" ||
      (to.path as string).startsWith("/main") ||
      (to.path as string).startsWith("/oauth") ||
      (to.path as string).startsWith("/create-workspace-token")
    ) {
      next({ path: "/login", query: { redirect: to.path, queryparams: to.query } });
    } else {
      next();
    }
  }
};

@Component
export default class Start extends Vue {
  public beforeRouteEnter(to, from, next) {
    startRouteGuard(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    startRouteGuard(to, from, next);
  }
}
